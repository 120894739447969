<template>
    <div class="user-container">
        <CRow>
            <CCol col="12">
                <CCard class="p-0">
                    <CCardHeader>
                        <div class="d-flex">
                            <div class="flex-grow-1">
                                <h5>Dashboard Summary Information Document</h5>
                            </div>
                        </div>
                    </CCardHeader>
                    <CCardBody>
                        <CRow>
                            <CCol>
                                <div class="d-flex" style="margin-bottom:10px!important">
                                    <div class="flex-grow-1">
                                    </div>
                                    <div class="flex-grow-0 ml-10">
                                        <div class="d-inline-flex justify-center">
                                            <div>
                                                <CButton v-if="!filterChart.showFilter" @click="filterChart.showFilter = !filterChart.showFilter" class="btn-outline-danger btn-sm">
                                                    Filter<font-awesome-icon icon="filter"/>
                                                </CButton>
                                                <CButton v-else @click="filterChart.showFilter = !filterChart.showFilter" class="btn-sm">
                                                    <font-awesome-icon icon="times"/>
                                                </CButton>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <CCollapse :show="filterChart.showFilter" :duration="400">
                                    <CCard class="p-0"><CCardBody>
                                        <div class="d-flex">
                                            <div class="flex-grow-1">
                                                <h5>Filter</h5>
                                            </div>
                                        </div>
                                        <CRow class="filter-container">
                                            <CCol col="5">
                                                <div class="form-input">
                                                    <label class="label">Tanggal Awal</label>
                                                    <date-picker
                                                        v-model="filterChart.startDate"
                                                        :default-value="filterChart.startDate"
                                                        placeholder="YYYY-MM-DD"
                                                        value-type="format"
                                                        type="date"
                                                        class="w-100">
                                                    </date-picker>
                                                </div>
                                            </CCol>
                                            <CCol col="5">
                                                <div class="form-input">
                                                    <label class="label">Tanggal Akhir</label>
                                                    <date-picker
                                                        v-model="filterChart.endDate"
                                                        :default-value="filterChart.startDate"
                                                        placeholder="YYYY-MM-DD"
                                                        value-type="format"
                                                        type="date"
                                                        class="w-100">
                                                    </date-picker>
                                                </div>
                                            </CCol>
                                        </CRow>
                                        <div class="d-flex justify-end">
                                        <CButton @click="getChartInfo()" color="success" class="btn-sm mt-4">
                                            Terapkan
                                        </CButton>
                                        </div>
                                    </CCardBody></CCard>
                                </CCollapse>
                            </CCol>
                        </CRow>
                        <CRow>
                            <CCol col="12">
                                <chart-bar
									style="height:300px"
									:datasets="dataSetsCategory"
									:labels="labelCategory"
									:options="{
										maintainAspectRatio: false,
									}"
									/>
                            </CCol>
                        </CRow>
                    </CCardBody>
                </CCard>
            </CCol>
        </CRow>
    </div>
</template>

<script>
import 'vue-multiselect/dist/vue-multiselect.min.css'
import { mapState } from 'vuex'
import { CChartPie, CChartHorizontalBar } from '@coreui/vue-chartjs'
export default {
    name: 'Information Chart',

    data () {
        return {

            filterChart: {
                showFilter: false,
                startDate: null,
                endDate: null,
                chartType: 'category'
            },

            dataSetsCategory: [],
            labelCategory: [],
            barChartCategory: {
				data: [],
				label: 'Jumlah Surat',
				backgroundColor: '#E55353'
			},
        }
    },

    mounted () {
        this.getChartInfo()
    },

    methods: {
        getChartInfo() {
            this.$store.dispatch({
				type: 'summary/getChart',
				filter: this.filterChart
			}).then( res => {
				var result = res.result

                this.dataSetsCategory = []
                this.labelCategory = []
                this.barChartCategory.data = []

                if (result) {
                    result[0].chart_data.forEach(item => {
                        this.labelCategory.push(item.label)
                        this.barChartCategory.data.push(item.value)
                    })

                    this.dataSetsCategory.push(this.barChartCategory)
                }
            }).catch( err => {
			})

        }
    },

    components: {
        'chart-bar': CChartHorizontalBar
    }
}



</script>

<style scoped>

</style>